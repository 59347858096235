import { Box, Center, Grid } from '@chakra-ui/react'

export default function Section({ children }: { children: React.ReactNode }) {
  return (
    <Center>
      <Box>
        <Grid gap={16} templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} alignItems="center">
          {children}
        </Grid>
      </Box>
    </Center>
  )
}

export function ClaimSection({ children }: { children: React.ReactNode }) {
  return (
    <Box borderColor={'tertiary.base'} borderWidth={2} borderRadius={8} padding={8}>
      <Center>
        <Box>
          <Grid
            gap={16}
            templateColumns={{ base: '1fr', lg: 'repeat(2, 2fr)' }}
            alignItems="center"
          >
            {children}
          </Grid>
        </Box>
      </Center>
    </Box>
  )
}
