'use client'

import { useChain } from '@cosmos-kit/react'
import React from 'react'
import { Center } from '@chakra-ui/react'
import { defaultChainName } from '../../config'
import Layout from '../../components/layout'
import WelcomeCard from '../../components/welcomeCard'
import UserSummary from '../../components/userSummary'

export default function Home() {
  const { isWalletConnected } = useChain(defaultChainName)

  return (
    <Layout>
      {!isWalletConnected ? (
        <Center width={'$100vw'} height={'$100vh'}>
          <WelcomeCard />
        </Center>
      ) : (
        <Center>
          <UserSummary />
        </Center>
      )}
    </Layout>
  )
}
