import { Box, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { minDenomToDenom, roundCoinAmount } from '../lib/coin'

export default function CoinBadge({
  label,
  amount,
  denom,
  minDenom,
  decimals = 6,
  enabled = true,
  roundPrecision = 0,
}: {
  label: string
  amount: number
  denom: string
  minDenom: string
  decimals: number
  enabled: boolean
  roundPrecision?: number
}) {
  let denomLabel
  let _amount = amount
  if (amount < Math.pow(10, decimals) && amount > 0) {
    denomLabel = minDenom
  } else {
    denomLabel = denom
    _amount = minDenomToDenom(amount, decimals)
  }

  const isDisabled = !enabled

  const badgeStyles = {
    py: 2,
    //px: { base: 10, xl: 16 },
    //rounded: "full",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: isDisabled ? 'white' : 'teal.200',
    bg: useColorModeValue('primary.base', 'rgba(155, 81, 244, 0.3)'),
    px: 6,
    minHeight: 100,
    width: 300,
  }

  const amountLabel = roundCoinAmount(_amount, roundPrecision)
    .toLocaleString('en-GB', {
      minimumFractionDigits: roundPrecision,
      maximumFractionDigits: roundPrecision,
    })
    .replace(/,/g, ' ')

  return (
    <Box alignItems={'center'}>
      <Box {...badgeStyles}>
        <Stack direction={'column'} spacing={2} alignItems={'center'}>
          <Text align={'center'} as="b" color="white" fontWeight={700}>
            {label}
          </Text>
          <Text color="purple.200" fontWeight={300} fontSize={{ lg: 24, sm: 20 }}>
            {amountLabel} {denomLabel}
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}
