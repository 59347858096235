import { defaultChainName } from '../config'
import { Box, Center, Flex, Grid, Text } from '@chakra-ui/react'
import Section from './section'
import CoinBadge from './coinBadge'
import LinkBadge from './linkBadge'
import Spinner from './loading'
import UsernameText from './usernameText'
import { useDaoData } from '../hooks/useDaoData'

export default function UserSummary() {
  const { data: stakingData, isLoading: isLoadingData } = useDaoData(defaultChainName)

  const ownedKleo = parseInt(stakingData?.balance?.balance ?? '0')
  const stakedKleo = parseInt(stakingData?.staking?.balance ?? '0')

  return (
    <Box maxW="container.xl" mx="auto" p={{ xl: 40, base: 4 }}>
      <Flex flexDir={{ lg: 'row', base: 'column' }}>
        <Box>
          <Center mb={8}>
            <Box pb={2}>
              <Text
                fontSize={{ sm: 'xl', lg: '8xl' }}
                fontWeight={700}
                p={4}
                style={{ whiteSpace: 'nowrap' }}
              >
                Welcome, <UsernameText />
              </Text>
            </Box>
          </Center>
          {isLoadingData ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <>
              <Grid templateColumns={{ base: '1fr', lg: 'repeat(1, 2fr)' }} gap={12}>
                <Section>
                  <CoinBadge
                    label="unbonded"
                    amount={ownedKleo}
                    denom="$KLEO"
                    minDenom="$uKLEO"
                    decimals={6}
                    enabled={true}
                  />
                  <CoinBadge
                    label="staked"
                    amount={stakedKleo}
                    denom="$KLEO"
                    minDenom="$uKLEO"
                    decimals={6}
                    enabled={true}
                  />
                </Section>
                <Section>
                  <LinkBadge href="/backdrop" text="claim BACKDROP" />
                  <LinkBadge href="/rewards" text="claim REVENUE SHARE" />
                </Section>
              </Grid>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
