import { AssetList, Asset } from '@chain-registry/types'
import { defaultChainName } from '../config'
import { assets } from 'chain-registry'
import BigNumber from 'bignumber.js'

export function minDenomToDenom(amount: number, decimals: number): number {
  if (amount === 0) return amount
  return amount / Math.pow(10, decimals)
}

export const getChainAssets = (chainName: string = defaultChainName) => {
  return assets.find(chain => chain.chain_name === chainName) as AssetList
}

export const getCoin = (chainName: string = defaultChainName) => {
  const chainAssets = getChainAssets(chainName)
  return chainAssets.assets[0] as Asset
}

export const getKleoCoin = (chainName: string = defaultChainName) => {
  const chainAssets = getChainAssets(chainName)
  return chainAssets.assets.find(asset => asset.display === 'KLEO') as Asset
}

export const getExponent = (chainName: string) => {
  return getCoin(chainName).denom_units.find(unit => unit.denom === getCoin(chainName).display)
    ?.exponent as number
}

export const shiftDigits = (num: string | number, places: number, decimalPlaces?: number) => {
  return new BigNumber(num)
    .shiftedBy(places)
    .decimalPlaces(decimalPlaces || 6)
    .toString()
}

export const toNumber = (val: string, decimals: number = 6) => {
  return new BigNumber(val).decimalPlaces(decimals).toNumber()
}

export const isGreaterThanZero = (val: number | string | undefined) => {
  return new BigNumber(val || 0).gt(0)
}

export const roundCoinAmount = (amount: number, precision: number) => {
  return Math.round((amount + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
}
