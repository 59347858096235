import { useChain } from '@cosmos-kit/react'
import { getExponent, getKleoCoin } from '../lib/coin'
import { useQuery } from '@tanstack/react-query'
import { useCosmwasmClient } from './useCosmwasmClient'
import { useEffect, useMemo } from 'react'

const tokenAddress = process.env.NEXT_PUBLIC_CW20_ADDRESS!
const stakingContractAddress = process.env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS!

export type DaoConfigResponse = {
  token_address: string
  unstaking_duration: {
    time: number
  }
}

export type CW20InfoResponse = {
  name: string
  symbol: string
  decimals: number
  total_supply: number
}

export type KleoBalanceResponse = {
  balance: string
}

export type KleoStakingResponse = {
  balance: string
  height: number
}

export const useDaoData = (chainName: string) => {
  const { address } = useChain(chainName)
  const { getClient } = useCosmwasmClient(chainName)
  const coin = getKleoCoin(chainName)
  const exp = getExponent(chainName)

  const isDataQueryEnabled = !!address

  const daoConfigQuery = useQuery<DaoConfigResponse, Error>({
    queryKey: ['daoConfig'],
    queryFn: async () => {
      const client = await getClient()
      return await client.queryContractSmart(stakingContractAddress, {
        get_config: {},
      })
    },
  })

  const cw20InfoQuery = useQuery<CW20InfoResponse, Error>({
    queryKey: ['cw20Info'],
    queryFn: async () => {
      const client = await getClient()
      return await client.queryContractSmart(tokenAddress, {
        token_info: {},
      })
    },
  })

  const balanceQuery = useQuery<KleoBalanceResponse, Error>({
    queryKey: ['kleoCW20BalanceQuery'],
    queryFn: async () => {
      const client = await getClient()
      return await client.queryContractSmart(tokenAddress, {
        balance: { address },
      })
    },
    enabled: isDataQueryEnabled,
  })

  const stakingQuery = useQuery<KleoStakingResponse, Error>({
    queryKey: ['kleoDaoStakingBalance'],
    queryFn: async () => {
      const client = await getClient()
      return await client.queryContractSmart(stakingContractAddress, {
        staked_balance_at_height: { address },
      })
    },
    enabled: isDataQueryEnabled,
  })

  const allQueries = {
    tokenInfo: cw20InfoQuery,
    config: daoConfigQuery,
    balance: balanceQuery,
    staking: stakingQuery,
  }

  const updatableQueriesAfterMutation = [balanceQuery, stakingQuery]
  const queriesWithUnchangingKeys = [cw20InfoQuery]

  useEffect(() => {
    queriesWithUnchangingKeys.forEach(query => query.remove())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainName])

  const isInitialFetching = Object.values(allQueries).some(({ isLoading }) => isLoading)

  const isRefetching = Object.values(allQueries).some(({ isRefetching }) => isRefetching)
  const isLoading = isInitialFetching || isRefetching

  type AllQueries = typeof allQueries

  type QueriesData = {
    [Key in keyof AllQueries]: NonNullable<AllQueries[Key]['data']>
  }

  const data = useMemo(() => {
    if (isLoading) return

    return Object.fromEntries(
      Object.entries(allQueries).map(([key, query]) => [key, query.data]),
    ) as QueriesData
  }, [allQueries, isLoading])

  const refetch = () => {
    updatableQueriesAfterMutation.forEach(query => query.refetch())
  }

  return { data, isLoading, refetch }
}
