import { Box, Link, Text, useColorModeValue } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Image from 'next/image'

export default function LinkBadge({
  href,
  text,
  iconLink,
}: {
  href: string
  text: string
  iconLink?: string
}) {
  const badgeStyles = {
    py: 2,
    //px: { base: 10, xl: 16 },
    //rounded: "full",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    bg: useColorModeValue('primary.base', 'rgba(155, 81, 244, 0.3)'),
    px: 6,
    whileHover: { scale: 1.1 },
    whileTap: { scale: 0.9 },
    minHeight: 100,
    width: 300,
  }

  return (
    <Link href={href} _hover={{ textDecoration: 'none' }}>
      <Box alignItems={'center'}>
        <Box as={motion.div} {...badgeStyles} gap={1.5}>
          {iconLink && <Image src={iconLink} width={32} height={32} alt={'prova'}></Image>}
          <Text as="b" fontWeight={700}>
            {text}
          </Text>
        </Box>
      </Box>
    </Link>
  )
}
