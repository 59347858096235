import { useChain } from '@cosmos-kit/react'

export const useCosmwasmClient = (chainName: string) => {
  const { address, getCosmWasmClient } = useChain(chainName)

  const getClient = async () => {
    const client = await getCosmWasmClient()

    return client
  }

  return {
    getClient,
  }
}

export const useSigningCosmwasmClient = (chainName: string) => {
  const { address, getSigningCosmWasmClient } = useChain(chainName)

  const getClient = async () => {
    const client = await getSigningCosmWasmClient()

    return client
  }

  return {
    getClient,
  }
}
